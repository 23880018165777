import {
  CardContent, Grid, Typography
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import { CustomButton } from "assets/jss/ButtonStyle";
import { SampleFilePaper, SampleFileParentBox, StyledAtag, StyledTextareaAutosize, TopHeadingPaper, UploadCardParent, UploadStyledAppBar } from "assets/jss/GeneralStyle";
import { PortUploadTab } from "assets/jss/PortfolioStyle";
import { CalCardActions } from "assets/jss/RetirementStyle";
import CustomReactDropzone from "components/CommonComponent/CustomReactDropzone";
import CustomSelect from "components/CustomSelect/CustomSelect";
import moment from "moment";
import React,{ useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { toast } from "react-toastify";
import { parseCsv, parseExcel } from "utils/Utils";
import PortfolioTabPanel from "./PortfolioTabPanel";
const PortfolioUpload = props => {
  const requiredFields = ["Symbol", "Quantity"];
  // const BooleanFields = ["Tradeable", "Trust Flag"];
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [fileLists, setFileLists] = useState([]);
  const [fileBelongings, setFileBelongings] = useState({});
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [resetDropzoneValue, setResetDropzoneValue] = useState(false);

  const [
    stringCsv,
    setStringCsv,
  ] = useState(`Symbol,Tradeable,Position Name,Quantity,Price Override,Currency,Account Type,Institution Name,Primary Account Holder,Trust Flag,Asset Location,Broad Asset Type,Narrow Asset Type,Security Type,Employer,Short Des Override,Category Override
FXE,Yes,,500,,,Broker,Robinhood,,,,Fx,,,,,
GLD,Yes,,5000,,,Broker,Robinhood,,,,Commodities,,,,,
PPLT,No,,500,,,Broker,Robinhood,,,,Commodities,,,,,
SLV,Yes,,5000,,,Broker,Robinhood,,,,Commodities,,,,,`);

  useEffect(() => {
    if (
      (fileLists.length === 0 && value === 1) ||
      (stringCsv === "" && value === 0)
    ) {
      setDisableUploadButton(true);
    } else {
      setDisableUploadButton(false);
    }
  }, [fileLists, stringCsv]);

  /**
   * handle the tab switch view
   * @param {*} event
   * @param {*} newValue
   */
  const handleTabChange = (event, newValue) => {
    setFileLists([]);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  /**
   * get the selected file from dropzone
   * @param {*} fileList
   */
  const getListOfFiles = (fileList) => {
    setFileLists(fileList);
  };

  const passFileToParse = async (
    tabIndex,
    file,
    localDeletedData,
    parsedResult,
    skipParsed = false
  ) => {
    let localRes = {};
    const localSkipParsed = skipParsed;
    if (
      file.path &&
      (file.path.match(/.*\.xlsx?$/g) ||
        file.type === "application/vnd.ms-excel")
    ) {
      localRes = await parseExcel(file);
    } else {
      if (!localSkipParsed) {
        localRes = await parseCsv(file);
      } else {
        localRes = file;
      }
    }
    if (localRes.data.length === 0) {
      toast.error("Please enter valid data.");
      return;
    }
    // let reg = "";
    // if (tabIndex === 0) {
    //   reg = new RegExp(localRes.fileName, "g");
    // } else {
    //   reg = new RegExp("^[" + localRes.fileName + "]+$", "g");
    // }
    validateData(
      localRes,
      localDeletedData,
      parsedResult
    );
  };
  /**
   * convert excel string format to json format
   * @param {*} data
   * @returns
   */
  const convertStringExcelToJson = (data) => {
    let jsonData = [];
    let rows = data.split("\n");
    const header = [];
    for (var rowIndex in rows) {
      if (rowIndex !== undefined) {
        let cells = rows[rowIndex].split("\t");
        if (parseInt(rowIndex,10) !== 0) {
          jsonData[rowIndex - 1] = {};
        }
        for (let cellIndex in cells) {
          if (rowIndex === 0) {
            header.push(cells[cellIndex]);
          } else {
            jsonData[rowIndex - 1][header[cellIndex]] = cells[cellIndex] || null;
          }
        }
      }
    }
    return { data: jsonData, fileName: "Text Data" };
  };

   const validateData = (
     localRes,
     localDeletedData,
     parsedResult
   ) => {
     let responseLocal = localRes;
       const timeStamp=moment().format("x");
       // to add number of occurance file after the file name to make it unique name
       const currFileName = responseLocal.fileName.split(".");
       const fileExt =
         currFileName.length > 1
           ? "." + currFileName[currFileName.length - 1]
           : "";
       const fileNameWithoutExt = responseLocal.fileName.replace(fileExt, "");
       responseLocal = {
         ...responseLocal,
         fileName: `${fileNameWithoutExt}_${timeStamp}${fileExt}`,
       };
     const localFileData = JSON.parse(JSON.stringify(responseLocal));
      let arrToSend = [...new Set(responseLocal.data)];
     for (
       let parsedIndex = 0;
       parsedIndex < responseLocal.data.length;
       parsedIndex++
     ) {
       let eachData = responseLocal.data[parsedIndex];
       const isRequiredIncluded = requiredFields.some((val) => {
         if (eachData[val] === undefined || eachData[val] === null) {
           return val;
         }
         // validating the field data type
         switch (val) {
           case "Symbol":
             return !eachData[val].toString().match(/^([a-z0-9A-Z#*&_ ]{1,50})$/g);
           case "Quantity":
             return !eachData[val].toString().match(/^[0-9.]+$/g);
           default:
             return;
         }
       });
       const indexToAppedAt = arrToSend.findIndex(
         (x) => JSON.stringify(x) === JSON.stringify(eachData)
       );
        if (eachData["Tradeable"]) {
          const tradeVal = eachData["Tradeable"].toLowerCase();
          eachData = {
            ...eachData,
            Tradeable: tradeVal === "y" || tradeVal === "yes" ? true : false,
          };
        }
        if (eachData["Trust Flag"]) {
          const trustVal = eachData["Trust Flag"].toLowerCase();
          eachData = {
            ...eachData,
            "Trust Flag": trustVal === "y" || trustVal === "yes" ? true : false,
          };
        }
       arrToSend[indexToAppedAt] = {
           symbol: eachData['Symbol'],
           who: fileBelongings['who'] || null,
           what: fileBelongings['what'] || null,
           tradeable: eachData['Tradeable'] || false,
           position_name: eachData['Position Name'] || null,
           quantity: eachData['Quantity'],
           currency: eachData['Currency'] || null,
           account_type: eachData['Account Type'] || null,
           institution_name: eachData['Institution Name'] || null,
           primary_acc_holder: eachData['Primary Account Holder'] || null,
           price_override: eachData['Price Override'] || null,
           trust_flag : eachData['Trust Flag'] || null,
           asset_location : eachData['Asset Location'] || null,
           broad_asset_type : eachData['Broad Asset Type'] || null,
           narrow_asset_type : eachData['Narrow Asset Type'] || null,
           security_type : eachData['Security Type'] || null,
           employer : eachData['Employer'] || null,
           short_des_override : eachData['Short Des Override'] || null,
           category_override : eachData['Category Override'] || null,
           home_val: eachData['home_val'] || false
       };
       // save error rows in state and remove from main array of data
       if (isRequiredIncluded) {
         localDeletedData[responseLocal.fileName] =
           localDeletedData[responseLocal.fileName] || [];
         localDeletedData[responseLocal.fileName].push(eachData);
         localFileData.data.splice(parsedIndex, 1);
         arrToSend.splice(indexToAppedAt, 1);
       }
     }
     if (arrToSend.length > 0) {
       parsedResult.push({
         fileName: localFileData.fileName,
         data: arrToSend,
       });
     }
   };
  /**
   * save selected file with validating data
   * @param {*} e
   */
  const handleParseFile = async (e) => {
    let localDeletedData = {};
    let parsedResult = [];
    if (disableUploadButton) {
      toast.error("Please select file to upload.");
      return;
    }
    switch (value) {
      //text data
      case 0:
        var fileData = "";
        var skipParsed = false;
        if (stringCsv.split(",").length === 1) {
          fileData = convertStringExcelToJson(stringCsv);
          skipParsed = true;
        } else {
          fileData = stringCsv;
        }
        await passFileToParse(
          0,
          fileData,
          localDeletedData,
          parsedResult,
          skipParsed
        );
        break;
      case 1:
        //local files
        for (let index = 0; index < fileLists.length; index++) {
          const file = fileLists[index];
          await passFileToParse(
            1,
            file,
            localDeletedData,
            parsedResult
          );
        }
        break;
      default:
        break;
    }
    if (Object.keys(parsedResult).length > 0) {
      props.uploadPortfolio(parsedResult);
    }
    if (Object.keys(localDeletedData).length) {
       setFileLists([]);
       setResetDropzoneValue(!resetDropzoneValue);
      return toast.error(<Box >
        Below rows are removed due to missing required fields ({requiredFields.toString()}),<br />
        <br />
        {Object.keys(localDeletedData).map((val, deletedIndex) => {
          return (
            <div style={{ wordBreak: 'break-all' }}>
              {deletedIndex + 1}. {val} :{" "}
              {localDeletedData[val].length % 2
                ? localDeletedData[val].length + " row"
                : localDeletedData[val].length + " rows"}
            </div>
          );
        })}</Box>, { autoClose: false })
    }
    setFileLists([]);
    setResetDropzoneValue(!resetDropzoneValue);
  };


  // to handle selected value of who and what drop down
  const handleOnChange = (e) => setFileBelongings({ ...fileBelongings, [e.target.name]: e.target.value });

  return (
      <>
          <TopHeadingPaper elevation={2}>
              <Typography variant="h6" sx={{ mb: 2, display: 'flex' }} gutterBottom className="top-heading-text">
                  Input Form
              </Typography>
          </TopHeadingPaper>

          <UploadCardParent elevation={2}>
              <CardContent>
                  <Grid container spacing={2} mb="10px">
                      <Grid item xs={12} md={6} sm={6}>
                          <CustomSelect
                              disableEmptyValue={true}
                              labelText={'Who'}
                              name="who"
                              onChange={handleOnChange}
                              id="who"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              options={[
                                  { value: 'me', label: 'For Me' },
                                  { value: 'another', label: 'For Another' }
                              ]}
                              style={{ paddingTop: 0 }}
                          />
                      </Grid>
                      <Grid item xs={12} md={6} sm={6}>
                          <CustomSelect
                              disableEmptyValue={true}
                              labelText={'What'}
                              name="what"
                              onChange={handleOnChange}
                              id="what"
                              formControlProps={{
                                  fullWidth: true
                              }}
                              options={[
                                  { value: 'bank', label: 'Bank' },
                                  { value: 'brokerage', label: 'Brokerage' },
                                  { value: 'other', label: 'Other' }
                              ]}
                              style={{ paddingTop: 0 }}
                          />
                      </Grid>
                  </Grid>
                  <UploadStyledAppBar position="static" color="default">
                      <Tabs
                          value={value}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          // variant="scrollable"
                          allowScrollButtonsMobile={true}
                          aria-label="full width portfolio sub tab"
                      >
                          <PortUploadTab label="Text Data" {...props.a11yProps(0)} />
                          <PortUploadTab label="Local File(s)" {...props.a11yProps(1)} />
                      </Tabs>
                  </UploadStyledAppBar>
                  <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                      <PortfolioTabPanel value={value} index={0} dir={theme.direction}>
                          <StyledTextareaAutosize
                              aria-label="minimum height"
                              minRows={20}
                              placeholder="String input"
                              defaultValue={stringCsv}
                              onChange={(e) => setStringCsv(e.target.value)}
                          />
                          <Box mt={'30px'}>
                              <Typography variant="h6">{'Note:'}</Typography>
                              <Typography variant="body1">
                                  1. You can copy and paste your csv file text or excel file text in above box.
                              </Typography>
                              <Typography variant="body1">2. Only 5 files can be saved.</Typography>
                          </Box>
                      </PortfolioTabPanel>
                      <PortfolioTabPanel value={value} index={1} dir={theme.direction}>
                          <CustomReactDropzone
                              accept="text/csv, .csv,application/vnd.ms-excel,.xlsx,.xls"
                              handleDroppedFiles={getListOfFiles}
                              resetDropzoneValue={resetDropzoneValue}
                          />
                          <Box mt={'30px'}>
                              <Typography variant="h6">{'Note:'}</Typography>
                              <Typography variant="body1">1. Acceptable file types: CSV, Excel(.xls,.xlsx)</Typography>
                              <Typography variant="body1">2. Only 5 files can be saved.</Typography>
                          </Box>
                          {fileLists.length === 0 ? (
                              <>
                                  <Typography gutterBottom id="sampleTitle" color={'secondary'}>
                                      Please download the sample CSV /Excel file
                                  </Typography>
                                  <SampleFileParentBox>
                                      <SampleFilePaper>
                                          <Box>
                                              <StyledAtag href="/CSVSample/TPE_Port_Input.csv" download>
                                                  <img src="img/csv-file-icon.svg" alt="TPE csv sample file" />
                                              </StyledAtag>
                                              <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                  CSV Sample
                                              </Typography>
                                          </Box>
                                      </SampleFilePaper>
                                      <SampleFilePaper>
                                          <Box>
                                              <StyledAtag href="/CSVSample/TPE_Port_Input.xls" download>
                                                  <img src="img/excel-file-icon.svg" alt="TPE excel sample file" />
                                              </StyledAtag>
                                              <Typography variant="body1" textAlign={'center'} m="10px auto">
                                                  Excel Sample
                                              </Typography>
                                          </Box>
                                      </SampleFilePaper>
                                  </SampleFileParentBox>
                              </>
                          ) : (
                              ''
                          )}
                      </PortfolioTabPanel>
                  </SwipeableViews>
                  <CalCardActions className="contactus-cardaction">
                      <CustomButton
                          type="submit"
                          onClick={handleParseFile}
                          disabled={disableUploadButton}
                          className={'success-btn'}
                          variant="contained"
                      >
                          Upload
                      </CustomButton>
                  </CalCardActions>
              </CardContent>
          </UploadCardParent>
      </>
  );
};

export default PortfolioUpload;
