import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { fetchDebtCal } from 'redux/actions/DebtCalActions';
import { resetEditTableField, submitDebtCal } from 'redux/actions/DebtCalActions';
import { submitProfile } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import { numberWithCommas } from 'utils/Utils';
import CustomInput from 'components/CustomInput/CustomInput';
import { AnnualInputWrapperGrid } from 'assets/jss/CalculatorStyle';
import { fetchIndexTypeValue } from 'redux/actions/DebtCalActions';

const indexTypeLabels = ['Prime', 'SoFR', 'Other/Custom'];
const debtTypeOptions = ['Auto', 'Personal', 'Student Loan', 'Mortgage'];

export const includes = (rows, ids, filterValue) => {
    return rows.filter((row) => {
        return ids.some((id) => {
            const rowValue = row.values[id] || '-';
            return filterValue === 'all' ? true : rowValue ? rowValue.includes(filterValue) : rowValue === filterValue;
        });
    });
};
const defaultValues = {
    debtName: '',
    prinAmt: '',
    maturityYrs: '',
    repayType: '',
    interestRate: '',
    debttype: '',
    actual_pay: '',
    fixed_floating: '',
    index: '',
    margin: ''
};
export const DebtTable = (props) => {
    const { debtdata, dispatch, profile } = props;
    const [enableEdit, setEnableEdit] = useState({});
    const [updatedVal, setUpdatedVal] = useState({});
    const [annualIncomeVal, setAnnualIncomeVal] = useState({});
    const [tableData, setTableData] = useState([]);
    const [enableEditAnnual, setEnableEditAnnual] = useState(false);
    const [option, setOption] = useState('');
    const [indexType, setIndexType] = useState('');
    const [creditCardOption, setCreditCardOption] = useState(true);
    const [floatingOption, setFloatingOption] = useState(true);
    const requiredFields = ['Principal Amount', 'Debt Type'];
    const container = React.useRef(null);
    const { handleSubmit, control, setValue, reset } = useForm({
        mode: 'onChange'
        //     defaultValues: {"printAmt": ''
        // ,"maturityYrs": 1,"repayType": 'io',"interestRate": 10,
        //       "debttype": 'creditcard',"actual_pay": 1200,"fixed_floating": 'fixed',"index": 2,
        //       "margin": 1},
    });

    useEffect(() => {
        // reset all flag and object when add new debt popup is opened
        if (debtdata.disableEditTblField) {
            setEnableEdit({});
            setUpdatedVal({});
            dispatch(resetEditTableField(false));
        }
    }, [debtdata.disableEditTblField]);

    const onSubmit = (data) => {
        if (Object.keys(enableEdit).length > 0) {
            let objToSend = {
                prinAmt: parseFloat(data.prinAmtUnFormattedValue || data.prinAmt?.toString().replace(/,/g, '')),
                fixed_floating: data.fixed_floating || 'fixed',
                id: enableEdit.id
            };
            const existingDebtNames = debtdata?.debtDetail?.debt_table.map((debt) => debt.debtName);

            if (data.maturityYrs) {
                objToSend = {
                    ...objToSend,
                    maturityYrs: data.maturityYrs && parseFloat(data.maturityYrs)
                };
            }
            if (data.debtName) {
                objToSend = {
                    ...objToSend,
                    debtName: data.debtName
                };
            }
            if (data.repayType) {
                objToSend = {
                    ...objToSend,
                    repayType: data.repayType
                };
            }
            if (data.interestRate) {
                objToSend = {
                    ...objToSend,
                    interestRate: data.interestRate && parseFloat(data.interestRate)
                };
            }
            if (data.debttype) {
                objToSend = {
                    ...objToSend,
                    debttype: data.debttype
                };
            }
            if (data.actual_pay) {
                objToSend = {
                    ...objToSend,
                    actual_pay: parseFloat(data.actual_payUnFormattedValue || data.actual_pay?.toString().replace(/,/g, ''))
                };
            }
            if (data.index) {
                objToSend = {
                    ...objToSend,
                    index: String(data.index)
                };
            }
            if (data.margin) {
                objToSend = {
                    ...objToSend,
                    margin: String(data.marginUnFormattedValue || data.margin?.toString().replace(/,/g, ''))
                };
            }
            if (data.debttype === 'creditcard') {
                objToSend = {
                    ...objToSend,
                    payment_type: data.payment_type
                };
            }
            if (indexType) {
                objToSend = {
                    ...objToSend,
                    index_type: indexType
                };
            }

            if (data.payment_type === 'PCT' && data.actual_pay > (data.prinAmtUnFormattedValue || data.prinAmt)) {
                return toast.error('Actual payment should not greater then principal amount.');
            }
            if (data.fixed_floating === 'floating' && !data.index_type) {
                return toast.error('Index Type is required for floating rate.');
            }
            if (data.fixed_floating === 'floating' && data.index_type === 'custom' && !data.index) {
                return toast.error('Please fill Index field.');
            }
            if (data.margin > 50) {
                return toast.error('Margin should be less then or equal to 50');
            }
            let type = 'put';
            if (enableEdit.id === 0) {
                type = 'post';
                if (existingDebtNames.includes(data.debtName)) {
                    return toast.error('This Debt Name is already exist.');
                }
            }
            else{
                const excludedDebtNames = debtdata?.debtDetail?.debt_table.map((debt) => debt.id !== data.id ? debt.debtName : "");
                if (excludedDebtNames.includes(data.debtName)) {
                    return toast.error('This Debt Name is already exist.');
                }
            }
            dispatch(submitDebtCal([objToSend], type));
            reset();
            setEnableEdit({});
            setOption('');
            setIndexType('');
            setCreditCardOption(true);
            setFloatingOption(true);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    useEffect(() => {
        // add default value for add new item from table while clicking on plus icon from table
        let tabledata = [
            {
                id: 0,
                ...defaultValues
            }
        ];
        if (debtdata.debtDetail['debt_table']) {
            tabledata = [...tabledata, ...debtdata.debtDetail['debt_table']];
        }
        setTableData(tabledata);
    }, [debtdata.debtDetail]);

    useEffect(() => {
        const handleShowFields = async () => {
            if (option === 'Credit Card') {
                setCreditCardOption(false);
                setValue('payment_type', 'USD');
            }
            if (debtTypeOptions.includes(option)) {
                setCreditCardOption(true);
                setValue('payment_type', 'USD');
            }
            if (option === 'Floating') {
                setFloatingOption(false);
            }
            if (option === 'Fixed') {
                setIndexType('');
                setFloatingOption(true);
                setValue('index_type', '');
                setValue('index', '');
            }
            if (indexTypeLabels.includes(option)) {
                let res;
                if (option === 'Other/Custom') {
                    res = await dispatch(fetchIndexTypeValue('custom'));
                } else {
                    res = await dispatch(fetchIndexTypeValue(option.toLowerCase()));
                }
                setValue('index', res.index_value);
                setIndexType(res.index_type);
            }
            setOption(option);
        };
        handleShowFields();
    }, [option]);

    const handleEdit = async (data) => {
        if (data.debttype === 'creditcard') {
            setCreditCardOption(false);
        }
        if (data.debttype !== 'creditcard') {
            setCreditCardOption(true);
        }
        if (data.fixed_floating === 'floating') {
            setFloatingOption(false);
        }
        if (data.fixed_floating !== 'floating') {
            setFloatingOption(true);
            setValue('index_type', '');
        }
        if (data.index_type === 'custom' && data.fixed_floating === 'floating') {
            setIndexType('custom');
        }
    };

    // Create an editable cell renderer
    const EditableCell = (initialValue, type, row, cell, disabled, options = [], extra = { commaSep: true }) => {
        let valToPass = initialValue || '',
            custompattern = '';
        // We need to keep and update the state of the cell normally
        const [value, setCellValue] = useState(updatedVal);

        switch (type) {
            case 'number':
                custompattern = 'decimalRate';
                // valToPass = extra.commaSep
                //     ? !isNaN(initialValue) && initialValue?.toString().indexOf('.') !== -1
                //         ? numberWithCommas(initialValue?.toFixed(2))
                //         : numberWithCommas(initialValue)
                //     : initialValue;
                break;
            case 'select':
                valToPass = initialValue?.toString().toLowerCase();
                break;
            default:
                custompattern = 'validString';
                break;
        }
        valToPass = valToPass || '';
        if (value[cell.column.id] !== undefined && value[cell.column.id] !== null) {
            valToPass = value[cell.column.id];
        }
        const id = [row.original.id, cell.column.id].join('_');

        return (
            <RenderField
                label={''}
                id={id}
                defaultValue={valToPass}
                name={cell.column.id}
                type={type}
                fieldName={cell.column.id}
                options={options}
                control={control}
                required={requiredFields.includes(cell.column.Header)}
                thousandSeparator={cell.column.Header === 'Index' ? false : extra?.commaSep || false}
                customPatternName={extra?.custompattern || custompattern}
                setValue={setValue}
                disabled={disabled}
                option={option}
                setOption={setOption}
                header={cell.column.Header}
                indexType={indexType}
                creditCardOption={creditCardOption}
                floatingOption={floatingOption}
                {...extra}
                onKeyDown={handleKeyDown}
            />
        );
    };
    const deleteSelectedData = (ids) => dispatch(submitDebtCal([ids], 'delete'));
    const handleRequiredFields = (opt, key) => {
        if (opt) {
            requiredFields.push(key);
        } else {
            let indexOfStringToRemove = requiredFields.findIndex((str) => str === key);
            if (indexOfStringToRemove !== -1) {
                requiredFields.splice(indexOfStringToRemove, 1);
            }
        }
    };
    const columns = useMemo(() => {
        const result = [
            {
                Header: () => null,
                accessor: 'id',
                isVisible: false
            },
            {
                Header: 'Action',
                Cell: ({ row }) => {
                    if (enableEdit.id === row.original.id) {
                        return (
                            <div className={'actionWrapper'}>
                                <Tooltip title="Cancel">
                                    <IconButton
                                        aria-label="cancel"
                                        onClick={(e) => {
                                            reset(defaultValues);
                                            setEnableEdit({});
                                            setOption('');
                                            setIndexType('');
                                            setCreditCardOption(true);
                                            setFloatingOption(true);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Save">
                                    <IconButton type="submit" aria-label="save">
                                        <DoneIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return (
                            <div className={'actionWrapper'}>
                                {row.original.id === 0 ? (
                                    <Tooltip title="Add">
                                        <IconButton
                                            id="tab-8"
                                            type="submit"
                                            aria-label="add"
                                            onClick={() => {
                                                if (profile.annualincome) {
                                                    setUpdatedVal({});
                                                    setEnableEdit(row.original);
                                                    reset(defaultValues);
                                                } else {
                                                    return toast.error('Please enter Annual Income.');
                                                }
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <>
                                        <Tooltip title="Delete">
                                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(row.original.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton
                                                aria-label="edit"
                                                onClick={() => {
                                                    if (profile.annualincome) {
                                                        setUpdatedVal({});
                                                        for (const key in row.original) {
                                                            if (Object.hasOwnProperty.call(row.original, key)) {
                                                                let element = row.original[key];
                                                                let valueKEY = key;
                                                                setValue(valueKEY, element);
                                                            }
                                                        }
                                                        setEnableEdit(row.original);
                                                        handleEdit(row.original);
                                                    } else {
                                                        return toast.error('Please enter Annual Income.');
                                                    }
                                                }}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="chart">
                                            <IconButton
                                                type="submit"
                                                aria-label="chart"
                                                onClick={() => {
                                                    props.setEnableChart(row.original);
                                                }}
                                            >
                                                <ShowChartIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        );
                    }
                }
            },
            {
                Header: 'Debt Name',
                width: 150,
                accessor: 'debtName',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    const disabled = false;
                    return enableEdit.id === row.original.id ? EditableCell(value, 'text', row, cell, disabled) : value || '-';
                }
            },
            {
                Header: 'Principal Amount',
                width: 150,
                accessor: 'prinAmt',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    const disabled = false;

                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell, disabled)
                        : numberWithCommas(value) || '-';
                }
            },
            {
                Header: 'Maturity Years',
                accessor: 'maturityYrs',
                disableFilters: true,
                Cell: ({ value, row, cell }) => {
                    handleRequiredFields(creditCardOption, 'Maturity Years');
                    const disabled = !creditCardOption;

                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell, disabled, [], { commaSep: false, maxLength: 2 })
                        : value || '-';
                }
            },
            {
                Header: 'Repay Type',
                accessor: 'repayType',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell, ...all }) => {
                    handleRequiredFields(creditCardOption, 'Repay Type');
                    const disabled = !creditCardOption;

                    const option = [
                        { label: 'Interest Only', value: 'io' },
                        { value: 'pi', label: 'Principal and Interest' }
                    ];
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, disabled, option)
                                : option.filter((val) => val.value === value?.toLowerCase())[0]?.label || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Interest Rate',
                accessor: 'interestRate',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    handleRequiredFields(floatingOption, 'Interest Rate');

                    const disabled = !floatingOption;

                    return enableEdit.id === row.original.id
                        ? EditableCell(value, 'number', row, cell, disabled, [], { commaSep: false })
                        : value || '-';
                }
            },
            {
                Header: 'Debt Type',
                accessor: 'debttype',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const disabled = false;

                    const option = [
                        { label: 'Auto', value: 'auto' },
                        { value: 'creditcard', label: 'Credit Card' },
                        { label: 'Mortgage', value: 'mortgage' },
                        { label: 'Personal', value: 'personal' },
                        { label: 'Student Loan', value: 'studloan' }
                    ];
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, disabled, option)
                                : option.filter((val) => val.value === value?.toLowerCase())[0]?.label || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Actual Payment Made',
                accessor: 'actual_pay',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    handleRequiredFields(!creditCardOption, 'Actual Payment Made');

                    const disabled = creditCardOption;

                    return enableEdit.id === row.original.id ? EditableCell(value, 'number', row, cell, disabled) : value || '-';
                }
            },
            {
                Header: 'Fixed/Floating',
                accessor: 'fixed_floating',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const disabled = false;

                    const option = [
                        { label: 'Fixed', value: 'fixed' },
                        { value: 'floating', label: 'Floating' }
                    ];
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, disabled, option)
                                : option.filter((val) => val.value === value?.toLowerCase())[0]?.label || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Margin',
                accessor: 'margin',
                disableFilters: true,
                width: 100,
                Cell: ({ value, row, cell }) => {
                    const disabled = floatingOption;

                    return enableEdit.id === row.original.id ? EditableCell(value, 'number', row, cell, disabled) : value || '-';
                }
            },
            {
                Header: 'Index Value',
                accessor: 'index',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const disabled = true;
                    return enableEdit.id === row.original.id ? EditableCell(value, 'number', row, cell, disabled) : value || '-';
                }
            },

            {
                Header: 'Index Type',
                accessor: 'index_type',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    // handleRequiredFields(!floatingOption, 'Index Type');

                    const disabled = floatingOption;
                    const option = [
                        { label: 'Prime', value: 'prime' },
                        { label: 'SoFR', value: 'sofr' },
                        { label: 'Other/Custom', value: 'custom' }
                    ];
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, disabled, option)
                                : option.filter((val) => val.value === value?.toLowerCase())[0]?.label || '-'}
                        </div>
                    );
                }
            },
            {
                Header: 'Payment Type',
                accessor: 'payment_type',
                disableFilters: true,
                width: 200,
                Cell: ({ value, row, cell }) => {
                    const disabled = creditCardOption;
                    const option = [
                        { label: 'USD', value: 'USD' },
                        { value: 'PCT', label: 'PCT' }
                    ];
                    return (
                        <div>
                            {enableEdit.id === row.original.id
                                ? EditableCell(value, 'select', row, cell, disabled, option)
                                : option.filter((val) => val.value.toLowerCase() === value?.toLowerCase())[0]?.label || '-'}
                        </div>
                    );
                }
            }
        ];
        return result;
    }, [enableEdit, updatedVal, profile, creditCardOption, floatingOption, option, indexType]);

    const refetchDebtData = () => {
        dispatch(fetchDebtCal());
    };
    const handleAnnualIncome = () => {
        if (Object.keys(annualIncomeVal).length > 0) {
            if (annualIncomeVal.unformattedVal) {
                if (annualIncomeVal.unformattedVal.match(/^[0-9,]*?(\.[0-9]{1,2})?$/g)) {
                    dispatch(submitProfile({ annualincome: annualIncomeVal.unformattedVal || 0 }, refetchDebtData));
                    handleCancelEditAnnual();
                } else {
                    return toast.error('Please enter numeric value.');
                }
            } else {
                return toast.error('Annual Income required.');
            }
        }
    };
    const inputChangedHandler = (e) => e.target.value.replace(/,/g, '');
    const handleAnnualChange = (e) => {
        setAnnualIncomeVal({
            unformattedVal: inputChangedHandler(e),
            val: numberWithCommas(e.target.value)
        });
    };
    const handleCancelEditAnnual = () => {
        setAnnualIncomeVal({});
        setEnableEditAnnual(false);
    };
    let valToPass = numberWithCommas(profile.annualincome) || '';
    if (enableEditAnnual) {
        valToPass = annualIncomeVal?.val;
    }

    return (
        <>
            {/* <Grid item xs={12} sm={9} md={8} lg={8}> */}
            <Grid container className="widget-full-width">
                <AnnualInputWrapperGrid item xs>
                    <CustomInput
                        labelText="Your Annual Income *"
                        value={valToPass}
                        id="annualincome"
                        disabled={!enableEditAnnual}
                        type="text"
                        CustomLabelProps={{ shrink: true }}
                        placeholder="Annual Income"
                        onChange={(e) => handleAnnualChange(e)}
                    />
                    {enableEditAnnual ? (
                        <div className={'actionWrapperAnnual'}>
                            <Tooltip title="Cancel">
                                <IconButton aria-label="cancel" onClick={handleCancelEditAnnual}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Save">
                                <IconButton aria-label="save" onClick={handleAnnualIncome}>
                                    <DoneIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ) : (
                        <div className={'actionWrapperAnnual'}>
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit" onClick={() => setEnableEditAnnual(true)} id="tab-7">
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                </AnnualInputWrapperGrid>
                <AnnualInputWrapperGrid item xs="auto">
                    <Box ref={container} />
                </AnnualInputWrapperGrid>
            </Grid>
            {/* </Grid> */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <EnhancedTable
                    searchcontainer={props.searchcontainer}
                    enableSearch={true}
                    columns={columns}
                    container={container}
                    data={tableData}
                    enableSort={true}
                />
            </form>
        </>
    );
};
