import { Tab, Tabs, Typography, useTheme } from '@mui/material';
import { WidgetBox } from 'assets/jss/GeneralStyle';
import { LargeContainer, MainBox } from 'assets/jss/GeneralStyle';
import { CustomListItem, CustomListItemText } from 'assets/jss/HeaderStyle';
import { AddUserOptionBox } from 'assets/jss/PortfolioStyle';
import { TopBlockBox } from 'assets/jss/PortfolioStyle';
import CommonCalculator from 'components/CommonComponent/CommonCalculator.js';
import Loader from 'components/CommonComponent/Loader.js';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { a11yProps } from 'utils/Utils';
import PortfolioTabPanel from '../PortfolioValueUpload/PortfolioTabPanel';
import AddNewTicker from './AddNewTicker';
import MutualComparison from './MutualComparison';
import MutualFundDesc from './MutualFundDesc';
import { MutualFundTable } from './MutualFundTable';
import { fetchAllTickers } from 'redux/actions/MutualFundActions';
import DoRegistration from 'views/DoRegistration';
import Meta from 'components/meta';

const tabs = [{ label: 'Your Fund(s)' }, { label: 'Comparison' }];

export default function FundComparison(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [tickerList, setTickerList] = useState([]);
    const handleTabChange = (e, newValue) => {
        if (typeof newValue !== 'undefined') setSelectedTab(newValue);
    };
    const mutualfund = useSelector((state) => state.mutualfund);
    const [selectedTab, setSelectedTab] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [activeTab, setActiveTab] = useState(0);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'mtFund';
        }
    }, []);

    // fetch debt detail on page load
    useEffect(() => {
        dispatch(fetchAllTickers());
    }, []);

    useEffect(() => {
        if (mutualfund.tickerList) {
            const list = mutualfund.tickerList.map((val) => ({
                value: val.Ticker,
                label: val.Ticker + ` (${val['Fund Name']})`,
                id: val.id
            }));
            setTickerList(list);
        }
    }, [mutualfund.tickerList]);

    useEffect(() => {
        const scrollContainer = document.querySelector('.scroll');
        const lastTab = document.querySelector('.scroll > div:last-child');
        if (!scrollContainer || !lastTab) return;

        const containerRight = scrollContainer.getBoundingClientRect().right;
        const lastTabRight = lastTab.getBoundingClientRect().right;

        const isLastTabVisible = lastTabRight <= containerRight || lastTabRight <= window.innerWidth;
        if (isLastTabVisible) {
            setScrollPosition(3);
        }
    }, [tabs]);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        const isEnd = scrollLeft + clientWidth >= scrollWidth - 1;
        if (scrollLeft === 0) {
            setScrollPosition(0);
        } else if (isEnd) {
            setScrollPosition(2);
        } else {
            setScrollPosition(1);
        }
    };

    const handleTabNavigation = (direction) => {
        const scrollContainer = document.querySelector('.scroll');
        if (!scrollContainer) return;

        const { scrollLeft, clientWidth } = scrollContainer;

        if (direction === 'backward') {
            scrollContainer.scrollTo({
                left: Math.max(0, scrollLeft - clientWidth),
                behavior: 'smooth'
            });
        } else if (direction === 'forward') {
            scrollContainer.scrollTo({
                left: scrollLeft + clientWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
        setSelectedTab(index);
    };
    const render = () => {
        return (
            <>
                {mutualfund.loader ? <Loader /> : ''}
                <TopBlockBox>
                    <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(231, 245, 255)' }}>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 0 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('backward')}
                        >
                            {' '}
                            &lt;
                        </div>
                        <div
                            style={{
                                overflowX: 'auto',
                                display: 'flex'
                            }}
                            className="scroll"
                            onScroll={handleScroll}
                        >
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: '0 0 auto',
                                        padding: '10px 20px',
                                        cursor: 'pointer',
                                        borderBottom: '3px solid transparent',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        borderBottomColor: activeTab === index ? 'rgb(39, 169, 224)' : 'transparent',
                                        color: activeTab === index ? 'rgb(39, 169, 224)' : 'black'
                                    }}
                                    onClick={() => handleTabClick(index)}
                                >
                                    {tab.label}
                                </div>
                            ))}
                            <AddUserOptionBox style={{ display: 'flex', flex: 'none' }}>
                                <AddNewTicker tickerList={tickerList} />
                            </AddUserOptionBox>
                        </div>
                        <div
                            style={{
                                cursor: 'pointer',
                                marginLeft: '20px',
                                marginRight: '20px',
                                visibility: scrollPosition !== 2 && scrollPosition !== 3 ? 'visible' : 'hidden'
                            }}
                            onClick={() => handleTabNavigation('forward')}
                        >
                            &gt;
                        </div>
                    </div>
                    {/* <Tabs
                        className="topblock-tabs"
                        value={selectedTab}
                        onChange={handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        allowScrollButtonsMobile={true}
                        aria-label="full width tabs example"
                    >
                        <Tab label="Your Fund(s)" {...a11yProps(0)} />
                        <Tab label="Comparison" {...a11yProps(1)} />
                        <AddUserOptionBox>
                            <AddNewTicker tickerList={tickerList} />
                        </AddUserOptionBox>
                    </Tabs> */}
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={selectedTab}
                        onChangeIndex={(index) => {
                            setActiveTab(index);
                            setSelectedTab(index);
                        }}
                        style={{ overflowX: 'initial' }}
                        slideStyle={{ overflow: 'initial' }}
                    >
                        <PortfolioTabPanel value={selectedTab} index={0} dir={theme.direction}>
                            <MutualFundTable
                                dispatch={dispatch}
                                mutualdata={mutualfund}
                                totalRecords={mutualfund?.paginationInfo?.total_items}
                            />
                        </PortfolioTabPanel>
                        <PortfolioTabPanel value={selectedTab} index={1} dir={theme.direction}>
                            <MutualComparison mutualdata={mutualfund} />
                        </PortfolioTabPanel>
                    </SwipeableViews>
                </TopBlockBox>
            </>
        );
    };
    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText primary={'Investments'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Mutual Fund
        </Typography>
    ];
    return (
        (props.widgetcal && <WidgetBox>{render()}</WidgetBox>) || (
            <>
                <Meta
                    title="Mutual Funds - Investment Opportunities and Insights | TPE Hub"
                    description="Explore comprehensive information on mutual funds, including investment opportunities, performance analysis, and expert insights. Learn how to diversify your portfolio with TPE Hub."
                    page="/mutual-fund-etf-tool"
                />
                <DoRegistration />
                <CommonCalculator variantText="Mutual Fund" breadcrumbs={breadcrumbs} />
                <MainBox>
                    <LargeContainer maxWidth="xl">
                        {render()}
                        <MutualFundDesc />
                    </LargeContainer>
                </MainBox>
            </>
        )
    );
}
