import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomButton } from 'assets/jss/ButtonStyle';
import { RangeButton, WatchListChartPaper } from 'assets/jss/DashboardStyle';
import { ChartCardParent } from 'assets/jss/PortfolioStyle';
import AreaCharts from 'components/Charts/AreaChart';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts';

const FixedDashboardChart = (props) => {
    const theme = useTheme();
    const [chartData, setChartData] = useState([]);
    const [rangeMax, setRangeMax] = useState(0);
    const [minValue, setMinValue] = useState(0);
    // const [isAnnualRangeSelected, setIsAnnualRangeSelected] = useState(false);
    const { secondary, warning, success, grey } = theme.palette;
    const fixedIncomeDashboardData = useSelector((state) => state.fixedIncomeHistoricalReducer.fixedIncomeHistoricalDetail);
    const [selectedRange, setSelectedRange] = useState('1y');
    const isMobile = window.innerWidth < 768;
    const chartWidth = isMobile ? window.innerWidth * 0.9 : window.innerWidth * 0.87;
    const rangeList = [
        { label: '1 Year', value: '1y' },
        { label: '3 Years', value: '3y' },
        { label: '5 Years', value: '5y' },
        { label: '10 Years', value: '10y' },
        { label: 'All', value: 'all' }
    ];
    let minValObj = {};

    // useEffect(() => {
    //     const closeValue = [];
    //     if (fixedIncomeDashboardData.data?.historical_data?.length > 0) {
    //         if (fixedIncomeDashboardData.data?.min_value != 0) {
    //             setMinValue(fixedIncomeDashboardData.data?.min_value);
    //             minValObj = {
    //                 ...minValObj,
    //                 [minValue]: minValue
    //             };
    //         }
    //         if (fixedIncomeDashboardData.data?.min_value <= 0) {
    //             setMinValue(fixedIncomeDashboardData.data?.min_value);
    //             minValObj = {
    //                 ...minValObj,
    //                 [minValue]: minValue
    //             };
    //         }
    //         const formatedData = fixedIncomeDashboardData.data.historical_data.map((val) => {
    //             closeValue.push(val.close);
    //             return { x: moment(val.Date).format('YYYY-MM-DD'), y: val.Value };
    //         });
    //         setChartData([{ id: props.chartdetails.ticker, data: formatedData }]);
    //         setRangeMax(Math.min(...closeValue));
    //     } else {
    //         setChartData([]);
    //     }
    // }, [fixedIncomeDashboardData]);

    // useEffect(() => {
    //     const handleResize = () => {
    //         setChartWidth(window.innerWidth * 0.8);
    //     };

    //     window.addEventListener('resize', handleResize);

    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);
    useEffect(() => {
        const closeValue = [];
        const formattedData = [];

        if (fixedIncomeDashboardData.data?.historical_data?.length > 0) {
            const min_value = fixedIncomeDashboardData.data?.min_value;

            if (min_value != null) {
                setMinValue(min_value);
                minValObj = { ...minValObj, [min_value]: min_value };
            }

            fixedIncomeDashboardData.data.historical_data.forEach((val) => {
                closeValue.push(val.close);
                const date = new Date(val?.Date);
                const year = date.getFullYear().toString().slice(-2);
                const month = date.toLocaleString('default', { month: 'short' });
                const yearMonth = `${year} ${month}`;
                formattedData.push({
                    name: moment(val.Date).format('MMM DD YYYY'),
                    fullDate: val.Date,
                    [props.chartdetails.ticker.toString()]: val.Value
                    // "Total Interest": val.Interest, // Add this if you want to show another line
                });
            });

            setChartData(formattedData);
            setRangeMax(Math.min(...closeValue));
        } else {
            setChartData([]);
        }
    }, [fixedIncomeDashboardData]);
    const formatValue = (value) => {
        if (value >= 1e9) {
            return `${(value / 1e9).toFixed(1)}B`;
        } else if (value >= 1e6) {
            return `${(value / 1e6).toFixed(1)}M`;
        } else if (value >= 1e3) {
            return `${(value / 1e3).toFixed(1)}K`;
        }
        return value;
    };

    const isAnnualRangeSelected = ['1y', '3y', '5y', '10y', 'all'].includes(selectedRange) || false;
    const priceChangeColor = Math.sign(props.chartdetails['pct_Change']) === 1 ? 'text.success' : 'text.error';
    return (
        <WatchListChartPaper elevation={2}>
            <CustomButton
                variant="text"
                startIcon={<ArrowBackIosIcon />}
                className="read-more back-button"
                onClick={() => {
                    setChartData([]);
                    props.backButtonClicked();
                }}
            >
                Back to table
            </CustomButton>
            <Box m={'16px 0 0 30px'}>
                <Typography variant="h5" gutterBottom>
                    {props.chartdetails['name']} ({props.chartdetails.ticker.toString()})
                </Typography>
                <Box display={'flex'}>
                    <Typography variant="h3"> {parseFloat(props.chartdetails.latest).toFixed(2)}</Typography>
                    {/* <Typography m={'15px 0 0 15px'} color={priceChangeColor}>
                        {props.chartdetails['pct_Change']?.toFixed(2)} 
                    </Typography> */}
                </Box>
            </Box>
            <Box textAlign={'center'}>
                {rangeList.map((val) => (
                    <RangeButton
                        variant="text"
                        onClick={() => {
                            setSelectedRange(val.value);
                            props.rangeSelectionClicked(val.value);
                        }}
                        className={`${selectedRange === val.value && 'selected'}`}
                    >
                        {val.label}
                    </RangeButton>
                ))}
            </Box>
            <ChartCardParent className="leave-space-dash" customheight={'450px'}>
                {chartData.length > 0 ? (
                    // <AreaCharts
                    //     dateType={true}
                    //     data={chartData}
                    //     enableArea={false}
                    //     enablePoint={false}
                    //     areamin={minValue}
                    //     rotateLabel={true}
                    //     isDateAxis={true}
                    //     annualData={isAnnualRangeSelected}
                    //     //   halfGraph={true}
                    //     customColor={[secondary.main, warning.dark, success.main, grey[500]]}
                    //     curveType={'linear'}
                    //     minVal={minValue}
                    //     minValObj={minValObj}
                    // />
                    <LineChart width={chartWidth} height={430} data={chartData || []}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="name"
                            height={80}
                            style={{ fontSize: '0.8rem' }}
                            tick={{ angle: -30, textAnchor: 'middle' }}
                            dy={10}
                        />
                        <YAxis yAxisId="left" domain={[rangeMax, 'auto']} tickFormatter={formatValue} />
                        <YAxis yAxisId="right" orientation="right" tickFormatter={formatValue} />
                        <Tooltip
                            labelFormatter={(label, payload) => {
                                const fullDate = payload?.[0]?.payload?.fullDate;
                                return fullDate ? moment(fullDate).format('MM/DD/YYYY') : label;
                            }}
                        />
                        {/* <Legend /> */}
                        <Line
                            yAxisId="left"
                            type="monotone"
                            dataKey={props.chartdetails.ticker.toString()}
                            stroke="blue"
                            activeDot={{ r: 8 }}
                        />
                    </LineChart>
                ) : (
                    <NoDataFound />
                )}
            </ChartCardParent>
        </WatchListChartPaper>
    );
};

export default FixedDashboardChart;
