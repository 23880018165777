import React from 'react';
import NewsFeedList from './NewsFeedList';
import NewsCategoryDescription from './NewsCategoryDescription';

const NewsCategory = ({ newsCategory, newsList, allCategories }) => {
    return (
        <section>
            <NewsCategoryDescription newsCategory={newsCategory} />
            <NewsFeedList newsList={newsList} newsCategory={newsCategory} allCategoriesColumn={allCategories} />
        </section>
    );
};

export default NewsCategory;
