import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { AppBar, Grid, Paper, Tab, Tabs, Typography, createStyles, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CommonCalculator from 'components/CommonComponent/CommonCalculator';

import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { LargeContainer } from 'assets/jss/GeneralStyle';
import styles from 'assets/jss/material-kit-react/views/generalPage';

import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import { useDispatch, useSelector } from 'react-redux';
import CustomScroll from 'components/CustomScroll';
import Meta from 'components/meta';
import NewsCategory from './NewsCategory';
import { getNewsFeed } from 'redux/actions/SettingActions';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...styles(theme)
    })
);
const fields = [{ name: 'All' }, { id: 'blogs', name: 'Blogs' }, { id: 'news', name: 'News' }];

const NewsFeed = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = mergedStyle();
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const [categorizeBlogs, setCategorizeBlogs] = useState({});
    const [activeTab, setActiveTab] = useState(2);
    const { newsList } = useSelector((state) => state.setting);
    const typographyRef = useRef(null);

    // const handleChangeIndex = (index) => {
    //     setActiveTab(index);
    //     setValue(index);
    // };
    // /**
    //  * handle the tab switch view
    //  * @param {*} event
    //  * @param {*} newValue
    //  */
    // const handleTabChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    useEffect(() => {
        if (activeTab === 0) {
            navigate('/knowledge-and-research');
        }
        if (activeTab === 1) {
            navigate('/knowledge-and-research/blog');
        } else if (activeTab === 2) {
            navigate('/knowledge-and-research/news');
        }
    }, [activeTab]);
    // const handleTabChange = (event, newValue) => {
    //     setValue(newValue);
    // };
    useEffect(() => {
        /// to fetch the default news feed values on page load
        dispatch(getNewsFeed());
    }, []);

    // useEffect(() => {
    //     localStorage.setItem('myBlog', JSON.stringify(dashboardBlogsList));
    // }, [dashboardBlogsList?.length]);

    useEffect(() => {
        const arr = [];
        newsList.forEach((news) => {
            if (news.is_published) {
                arr.push(news);
            }
        });
        const ModifiedObj = { All: arr };
        setCategorizeBlogs(ModifiedObj);
    }, [newsList]);

    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/knowledge-and-research')}
                primary={'News & Research'}
            />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            News Feed
        </Typography>
    ];

    const tabs = fields?.map((category) => {
        return { label: category?.name };
    });

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    return (
        <main>
            <Meta
                title="News Feed | TPE Hub"
                description="Explore comprehensive business news, studies, and insights on industry trends and innovations at TPE Hub. Stay informed and enhance your knowledge."
                page="/knowledge-and-research/news"
            />
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="News Feed" />
            <MainBox>
                <LargeContainer maxWidth="xl">
                    {/* <AccessControl allowedPermissions={['todo']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}> */}
                    <Paper elevation={0}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <AppBar position="static" color="default">
                                    <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
                                </AppBar>
                                {/* <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                > */}
                                {fields?.map((category, index) => {
                                    return (
                                        <PortfolioTabPanel value={value} index={index} dir={theme.direction}>
                                            <NewsCategory
                                                newsCategory={'News'}
                                                newsList={categorizeBlogs[category.name]}
                                                // allCategories={category.name === 'All' ? true : false}
                                            />
                                        </PortfolioTabPanel>
                                    );
                                })}
                                {/* </SwipeableViews> */}
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* </AccessControl> */}
                </LargeContainer>
            </MainBox>
        </main>
    );
};

export default NewsFeed;
