import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import { AppBar, Grid, Paper, Tab, Tabs, Typography, createStyles, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CommonCalculator from 'components/CommonComponent/CommonCalculator';

import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { LargeContainer } from 'assets/jss/GeneralStyle';
import styles from 'assets/jss/material-kit-react/views/generalPage';

import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import { a11yProps } from 'utils/Utils';
import { useDispatch, useSelector } from 'react-redux';
import BlogCategory from './BlogCategory';
import { fetchDashboardBlogsList } from 'redux/actions/BlogTypes';
import { fetchBlogDashboardCategory } from 'redux/actions/BlogTypes';
import CustomScroll from 'components/CustomScroll';
import { getBlogList } from 'redux/actions/GeneralCalActions';
import Meta from 'components/meta';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...styles(theme)
    })
);

const fields = [{ name: 'All' }, { id: 'blogs', name: 'Blogs' }, { id: 'news', name: 'News' }];

const KnowledgeResearchBlogs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = mergedStyle();
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const [categorizeBlogs, setCategorizeBlogs] = useState({});
    const { dashboardBlogsList, blogDashboardCategoryList } = useSelector((state) => state.blogReducer);
    const [activeTab, setActiveTab] = useState(0);
    const [activeMainTab, setActiveMainTab] = useState(1);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'knowReasch';
        }
    }, []);

    useEffect(() => {
        if (activeMainTab === 0) {
            navigate('/knowledge-and-research');
        }
        if (activeMainTab === 1) {
            navigate('/knowledge-and-research/blog');
        } else if (activeMainTab === 2) {
            navigate('/knowledge-and-research/news');
        }
    }, [activeMainTab]);

    const handleChangeIndex = (index) => {
        setActiveTab(index);
        setValue(index);
    };
    /**
     * handle the tab switch view
     * @param {*} event
     * @param {*} newValue
     */
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        dispatch(fetchDashboardBlogsList());
        dispatch(getBlogList());
        dispatch(fetchBlogDashboardCategory());
    }, []);

    useEffect(() => {
        localStorage.setItem('myBlog', JSON.stringify(dashboardBlogsList));
    }, [dashboardBlogsList?.length]);

    useEffect(() => {
        const data = {};
        const arr = [];
        dashboardBlogsList.forEach((blog) => {
            const categoryName = blog.category_name;
            if (!data[categoryName]) {
                data[categoryName] = [];
            }
            if (blog.is_published) {
                data[categoryName].push(blog);
                arr.push(blog);
            }
        });
        const ModifiedObj = { All: arr, ...data };
        setCategorizeBlogs(ModifiedObj);
    }, [dashboardBlogsList]);

    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <CustomListItem className="cal-banner-menu" key="2" sx={{ m: 0 }} color="text.light">
            <CustomListItemText
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/knowledge-and-research')}
                primary={'News & Research'}
            />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            Blogs and Research
        </Typography>
    ];

    const tabs = blogDashboardCategoryList.map((category) => {
        return { label: category.name };
    });

    const mainTabs = fields?.map((category) => {
        return { label: category?.name };
    });

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };
    const handleMainTabClick = (index) => {
        setActiveMainTab(index);
        setValue(index);
    };

    return (
        <main>
            <Meta
                title="In-Depth Knowledge Research | TPE Hub"
                description="Explore comprehensive research articles, studies, and insights on industry trends and innovations at TPE Hub. Stay informed and enhance your knowledge."
                page="/knowledge-and-research/blog"
            />
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="Blogs and Research" />
            <MainBox>
                <LargeContainer maxWidth="xl">
                    {/* <AccessControl allowedPermissions={['todo']} renderNoAccess={(plan) => <PermissionDenied planname={plan} />}> */}
                    <Paper elevation={0}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <AppBar position="static" color="default">
                                    <CustomScroll tabs={mainTabs} activeTab={activeMainTab} handleTabClick={handleMainTabClick} />
                                    <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
                                    {/* <Tabs
                                        className="topblock-tabs"
                                        value={value}
                                        onChange={handleTabChange}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        allowScrollButtonsMobile={true}
                                        aria-label="todo tabs -tpe"
                                    >
                                        {blogDashboardCategoryList.map((category,index)=>{
                                            return (<Tab key={index} label={category.name} {...a11yProps(index)} className={classes.appbarTab}/>)
                                        })}
                                    </Tabs> */}
                                </AppBar>
                                <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                >
                                    {blogDashboardCategoryList.map((category, index) => {
                                        return (
                                            <PortfolioTabPanel value={value} index={index} dir={theme.direction}>
                                                <BlogCategory
                                                    blogCategory={category.name}
                                                    blogsList={categorizeBlogs[category.name]}
                                                    allCategories={category.name === 'All' ? true : false}
                                                />
                                            </PortfolioTabPanel>
                                        );
                                    })}
                                </SwipeableViews>
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* </AccessControl> */}
                </LargeContainer>
            </MainBox>
        </main>
    );
};

export default KnowledgeResearchBlogs;
