import React from 'react';
import KnowledgeReasearchCategoryDescription from './KnowledgeReasearchCategoryDescription';
import KnowledgeReasearchView from './KnowledgeReasearchView';

const KnowledgeReasearchCategory = ({ researchCategory, activeTab }) => {
    return (
        <section>
            <KnowledgeReasearchCategoryDescription researchCategory={researchCategory} />
            <KnowledgeReasearchView activeTab={activeTab} researchCategory={researchCategory} />
        </section>
    );
};

export default KnowledgeReasearchCategory;
