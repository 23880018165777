import React, { useEffect } from 'react';
import { Box, Grid, Typography, Card, CardMedia, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { styled } from '@mui/system';

// Define custom styles for the Carousel arrows
const StyledCarousel = styled(Carousel)(({ theme }) => ({
    '& .MuiButtonBase-root': {
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            color: 'rgba(255, 255, 255, 1)'
        }
    },
    '& .MuiButtonBase-root:focus': {
        outline: 'none'
    }
}));

const NewsFeedTileView = ({ newsList }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    // Helper function to split news data into chunks of 11
    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array?.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const newsChunks = chunkArray(newsList || [], 11);

    useEffect(() => {
        localStorage.setItem('myNews', JSON.stringify(newsList));
    }, [newsList?.length]);

    const renderNewsLayout = (dataChunk) =>
        !isMobile ? (
            <>
                {/* Main Carousel (0th to 2nd) */}
                <Grid container spacing={2} sx={{ height: '100%', marginTop: '13px' }}>
                    <Grid item xs={6.8} sx={{ marginRight: '5px' }}>
                        <StyledCarousel indicators={false} navButtonsAlwaysVisible>
                            {dataChunk.slice(0, Math.min(dataChunk.length, 3)).map((news, index) => (
                                <a
                                    key={index}
                                    href={`/knowledge-and-research/news/${news?.id
                                        .toLowerCase()
                                        .replace(/\s+/g, '-')
                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                    target="_blank"
                                >
                                    <Box
                                        key={news?.id}
                                        sx={{
                                            position: 'relative',
                                            height: '100%'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                position: 'absolute',
                                                top: '10px',
                                                left: '10px',
                                                // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                // padding: '2px 2px',
                                                borderRadius: '5px'
                                            }}
                                        >
                                            <Typography variant="subtitle2" sx={{ color: 'gray', fontWeight: 'bold' }}>
                                                {news?.['News Source']}
                                            </Typography>
                                        </Box>
                                        <CardMedia
                                            component="img"
                                            height="100%"
                                            image={news?.Image}
                                            alt={news?.Headline}
                                            sx={{
                                                width: '100%',
                                                height: '376px',
                                                objectFit: 'cover',
                                                borderRadius: '10px'
                                            }}
                                        />
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                padding: '20px',
                                                color: '#fff'
                                            }}
                                        >
                                            {news?.Headline}
                                        </Typography>
                                    </Box>
                                </a>
                            ))}
                        </StyledCarousel>
                    </Grid>

                    {/* Right side (3rd to 5th) */}
                    <Grid item xs={5}>
                        <Grid container direction="column" spacing={2} sx={{ height: '100%' }}>
                            {dataChunk.slice(3, Math.min(dataChunk.length, 6)).map((news, index) => (
                                <a
                                    key={index}
                                    href={`/knowledge-and-research/news/${news?.id
                                        .toLowerCase()
                                        .replace(/\s+/g, '-')
                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                    target="_blank"
                                >
                                    <Grid item key={news?.id} sx={{ height: '120px', marginBottom: '5px' }}>
                                        <Card sx={{ display: 'flex', height: '100%' }}>
                                            <Box
                                                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5px' }}
                                            >
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {news?.Headline?.slice(0, 100) + '...'}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        marginTop: '4px'
                                                    }}
                                                >
                                                    {news?.['News Source']}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {news?.['Created At']}
                                                </Typography>
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: '120px', borderRadius: '10px' }}
                                                image={news?.Image}
                                                alt={news?.Headline}
                                            />
                                        </Card>
                                    </Grid>
                                </a>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Bottom Row (6th to 10th) */}
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={3.5}>
                        {dataChunk[6] && (
                            <a
                                href={`/knowledge-and-research/news/${dataChunk[6]?.id
                                    .toLowerCase()
                                    .replace(/\s+/g, '-')
                                    .replace(/[^a-z0-9\-]/g, '')}`}
                                target="_blank"
                            >
                                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '373px' }}>
                                    <CardMedia
                                        component="img"
                                        image={dataChunk[6]?.Image}
                                        alt={dataChunk[6]?.Headline}
                                        sx={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
                                    />
                                    <Box sx={{ padding: '10px' }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            {dataChunk[6]?.Headline?.slice(0, 100) + '...'}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                marginTop: '8px',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[6]?.['News Source']}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[6]?.['Created At']}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {dataChunk[6]?.Content?.slice(0, 100) + '...'}
                                        </Typography>
                                    </Box>
                                </Card>
                            </a>
                        )}
                    </Grid>

                    <Grid item xs={5}>
                        <Grid container direction="column" spacing={2}>
                            {dataChunk.slice(7, Math.min(dataChunk.length, 10)).map((news, index) => (
                                <a
                                    key={index}
                                    href={`/knowledge-and-research/news/${news?.id
                                        .toLowerCase()
                                        .replace(/\s+/g, '-')
                                        .replace(/[^a-z0-9\-]/g, '')}`}
                                    target="_blank"
                                >
                                    <Grid item key={news?.id} sx={{ height: '120px', marginBottom: '5px' }}>
                                        <Card sx={{ display: 'flex', height: '100%' }}>
                                            <Box
                                                sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5px' }}
                                            >
                                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                    {news?.Headline?.slice(0, 100) + '...'}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem',
                                                        marginTop: '4px'
                                                    }}
                                                >
                                                    {news?.['News Source']}
                                                </Typography>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        display: 'block',
                                                        color: 'gray',
                                                        fontWeight: 'bold',
                                                        fontSize: '0.8rem'
                                                    }}
                                                >
                                                    {news?.['Created At']}
                                                </Typography>
                                            </Box>
                                            <CardMedia
                                                component="img"
                                                sx={{ width: '120px', borderRadius: '10px' }}
                                                image={news?.Image}
                                                alt={news?.Headline}
                                            />
                                        </Card>
                                    </Grid>
                                </a>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={3.5}>
                        {dataChunk[10] && (
                            <a
                                href={`/knowledge-and-research/news/${dataChunk[10]?.id
                                    .toLowerCase()
                                    .replace(/\s+/g, '-')
                                    .replace(/[^a-z0-9\-]/g, '')}`}
                                target="_blank"
                            >
                                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '373px' }}>
                                    <CardMedia
                                        component="img"
                                        image={dataChunk[10]?.Image}
                                        alt={dataChunk[10]?.Headline}
                                        sx={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
                                    />
                                    <Box sx={{ padding: '10px' }}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                            {dataChunk[10]?.Headline?.slice(0, 100) + '...'}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                marginTop: '8px',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[10]?.['News Source']}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                display: 'block',
                                                color: 'gray',
                                                fontWeight: 'bold',
                                                fontSize: '0.8rem'
                                            }}
                                        >
                                            {dataChunk[10]?.['Created At']}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {dataChunk[10]?.Content?.slice(0, 100) + '...'}
                                        </Typography>
                                    </Box>
                                </Card>
                            </a>
                        )}
                    </Grid>
                </Grid>
            </>
        ) : (
            <Grid item xs={12}>
                {dataChunk.map((news, index) => (
                    <>
                        <a
                            key={index}
                            href={`/knowledge-and-research/news/${news?.id
                                .toLowerCase()
                                .replace(/\s+/g, '-')
                                .replace(/[^a-z0-9\-]/g, '')}`}
                            target="_blank"
                        >
                            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', maxHeight: '373px' }}>
                                <CardMedia
                                    component="img"
                                    image={news?.Image}
                                    alt={news?.Headline}
                                    sx={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
                                />
                                <Box sx={{ padding: '10px' }}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                        {news?.Headline?.slice(0, 100) + '...'}
                                    </Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            display: 'block',
                                            color: 'gray',
                                            fontWeight: 'bold',
                                            marginTop: '8px',
                                            fontSize: '0.8rem'
                                        }}
                                    >
                                        {news?.['News Source']}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {news?.Content?.slice(0, 100) + '...'}
                                    </Typography>
                                </Box>
                            </Card>
                        </a>
                        {index !== news.length - 1 ? <hr style={{ marginBottom: '3px' }} /> : null}
                    </>
                ))}
            </Grid>
        );

    return (
        <div>
            {newsChunks.map((newsChunk, index) => (
                <React.Fragment key={index}>{renderNewsLayout(newsChunk)}</React.Fragment>
            ))}
        </div>
    );
};

export default NewsFeedTileView;
