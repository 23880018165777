import { Alert, Button, Card, CardContent, Dialog, Divider, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import EnhancedTable from 'components/CommonComponent/CommonTable/EnhancedTable';
import NoDataFound from 'components/CommonComponent/NoDataFound';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getNewsFeed, submitNewsFeed, submitNewsFeedSuccess } from 'redux/actions/SettingActions';
import RenderField from 'utils/RenderField';
import { numberWithCommas } from 'utils/Utils';
import { CustomButton } from 'assets/jss/ButtonStyle';
import CustomDialogTitle from 'components/DialogTitle';
import { toast } from 'react-toastify';
import { NewsFeedUrl } from 'redux/actions/SettingActions';

const fieldsDetail = [
    {
        name: 'link',
        fieldName: 'News Link',
        fieldLabel: 'News Link  *',
        type: 'text',
        placeholder: 'Enter News Link',
        required: true,
        valid: 'noValid'
    },
    {
        name: 'title',
        fieldName: 'Headline',
        fieldLabel: 'Headline',
        type: 'text',
        placeholder: 'Enter Headline',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'media',
        fieldName: 'News Source',
        fieldLabel: 'News Source',
        type: 'text',
        placeholder: 'Enter News Source',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'date',
        fieldName: 'Date',
        fieldLabel: 'Date',
        type: 'datetime',
        placeholder: 'Enter Date',
        required: false
    },
    {
        name: 'image',
        fieldName: 'Image Link',
        fieldLabel: 'Image Link',
        type: 'text',
        placeholder: 'Enter Image Link',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'content',
        fieldName: 'News Content',
        fieldLabel: 'News Content',
        type: 'text',
        placeholder: 'Enter News Content',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'topic',
        fieldName: 'News Topic',
        fieldLabel: 'News Topic',
        type: 'text',
        placeholder: 'Enter Topic',
        required: false,
        valid: 'noValid'
    },
    {
        name: 'type',
        fieldName: 'News Type',
        fieldLabel: 'News Type',
        type: 'select',
        placeholder: 'Select News Type',
        options: [
            { value: 'manual', label: 'Manual' },
            { value: 'rss', label: 'Rss' }
        ],
        required: false
    },
    {
        name: 'summary',
        fieldName: 'News Summary',
        fieldLabel: 'News Summary',
        type: 'text',
        placeholder: 'Enter Summary',
        required: false,
        valid: 'noValid'
    }
];

const NewsFeedAdmin = (props) => {
    const { setting } = props;
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [moduleColl, setModuleColl] = React.useState([]);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isSubmitting },
        setValue
    } = useForm({
        mode: 'onChange'
    });

    //to submit the News Link data
    const handleOnChange = async (e) => {
        const value = e.target;
        if (value.id === 'link') {
            if (value.value.startsWith('https://')) {
                const response = await dispatch(NewsFeedUrl({ link: value?.value }));
                const formattedDate = response?.Date ? new Date(response?.Date).toISOString().split('T')[0] : null;
                setValue('title', response?.Headline);
                setValue('media', response?.['News Source']);
                setValue('date', formattedDate);
                setValue('image', response?.Image);
                setValue('content', response?.Content);
                setValue('topic', response?.Topic);
                setValue('type', response?.Type);
                setValue('summary', response?.Summary);
            }
        }
    };

    // submit the form
    const onSubmit = (data, isPublished) => {
        let type = 'post';
        // Format the date from ISO string to 'YYYY-MM-DD'
        const formattedDate =
            data?.date || data?.Date
                ? new Date(data.date || data.Date).toISOString().split('T')[0]
                : new Date().toISOString().split('T')[0];

        let objToPass = {
            title: data?.title || data?.Headline,
            media: data?.media || data?.['News Source'],
            date: formattedDate,
            link: data?.link || data?.Link,
            image: data?.image || data?.Image,
            content: data?.content || data?.Content,
            topic: data?.topic || data?.Topic,
            type: data?.type || data?.Type,
            summary: data?.summary || data?.Summary,
            is_published: isPublished
        };

        if (data?.Link) {
            if (!data?.Link || !data?.Link?.startsWith('https://')) {
                return toast.error('Please fill News Link...');
            }
        } else {
            if (!data?.link && !data?.link?.startsWith('https://')) {
                return toast.error('Please fill News Link...');
            }
        }

        if (data?.id) {
            objToPass = { ...objToPass, id: data.id };
            type = 'patch';
        }

        dispatch(submitNewsFeed(objToPass, type));
    };

    useEffect(() => {
        // to close opened popup after submittion of api
        if (setting.newsFeedFormDialog) {
            handleClose();
            dispatch(submitNewsFeedSuccess(false));
        }
    }, [setting.newsFeedFormDialog]);

    useEffect(() => {
        if (setting?.newsList.length > 0) {
            const ignoreFields = ['id', 'Created At', 'is_published'];
            let column = [];
            Object.keys(setting?.newsList[0]).forEach((key) => {
                if (!ignoreFields.includes(key)) {
                    const formattedKey = key.replaceAll(/_/g, ' ');
                    column.push({
                        Header: formattedKey || key,
                        accessor: key,
                        width: 150,
                        disableFilters: true,
                        Cell: ({ value }) => {
                            if (key === 'Link' && typeof value === 'string') {
                                return (
                                    <a href={value} target="_blank" rel="noopener noreferrer">
                                        {value}
                                    </a>
                                );
                            }

                            if ((key === 'Content' || 'Image') && typeof value === 'string') {
                                return value.length > 200 ? `${value.substring(0, 170)}...` : value;
                            }

                            return (
                                (!isNaN(value) && value?.toString().indexOf('.') !== -1
                                    ? numberWithCommas(value?.toFixed(2))
                                    : numberWithCommas(value)) ||
                                value ||
                                '-'
                            );
                        }
                    });
                }
            });

            column.unshift({
                Header: 'Action',
                width: 120,
                Cell: ({ row }) => (
                    <div className={'actionWrapper'}>
                        <Tooltip title="Delete">
                            <IconButton aria-label="delete" onClick={() => deleteSelectedData(row.original.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton aria-label="edit" onClick={() => handleEditData(row.original)}>
                                <CreateIcon />
                            </IconButton>
                        </Tooltip>
                        {row.original.is_published === false && (
                            <Tooltip title="Publish">
                                <IconButton aria-label="publish" onClick={() => onSubmit(row.original, true)}>
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                )
            });
            setModuleColl(column);
        }
    }, [setting.newsList]);

    useEffect(() => {
        /// to fetch the default news feed values on page load
        dispatch(getNewsFeed());
    }, []);

    const handleClickOpen = () => setOpen(true);

    // delete selected subscription plan
    const deleteSelectedData = (id) => dispatch(submitNewsFeed({ id: id }, 'delete'));

    const handleEditData = async (val) => {
        setOpen(true);
        const formattedData = {};

        for (const key in val) {
            if (Object.hasOwnProperty.call(val, key)) {
                let newKey;
                if (key === 'Headline') {
                    newKey = 'title';
                } else if (key === 'News Source') {
                    newKey = 'media';
                } else {
                    newKey = key.charAt(0).toLowerCase() + key.slice(1);
                }

                formattedData[newKey] = val[key];
            }
        }

        for (const key in formattedData) {
            setValue(key, formattedData[key]);
        }
    };

    //close popup
    const handleClose = () => {
        const resetObj = fieldsDetail.reduce((acc, cur) => ({ ...acc, [cur.name]: '' }), {});
        reset(resetObj || {});
        setOpen(false);
    };

    return (
        <>
            <Card elevation={2}>
                <CardContent>
                    <Tooltip title="Add" arrow>
                        <CustomButton
                            variant="text"
                            className="read-more"
                            startIcon={<AddIcon />}
                            aria-label="add"
                            onClick={handleClickOpen}
                        >
                            Add New Data
                        </CustomButton>
                    </Tooltip>
                    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                        <CustomDialogTitle id="form-dialog-title" onClose={handleClose}>
                            Add News Feed
                        </CustomDialogTitle>
                        <Divider />
                        <CustomDialogTitle>
                            <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
                                <Grid container>
                                    <Alert sx={{ marginLeft: '15px' }} severity="info" color="info">
                                        If you enter your News link, We will automatically populate the data available in the link
                                    </Alert>
                                    {fieldsDetail.map((val, i) => {
                                        return (
                                            <Fragment key={i + '_newsFeed'}>
                                                <Grid item xs={12} sm={4} alignSelf={'center'}>
                                                    <Typography variant="body2" component="p" className="outputLabel">
                                                        {val.fieldLabel}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <RenderField
                                                        name={val.name}
                                                        control={control}
                                                        label={''}
                                                        disableEmptyValue={true}
                                                        fieldName={val.fieldName}
                                                        id={val.name}
                                                        timeFormat={false}
                                                        handleOnChange={handleOnChange}
                                                        type={val.type}
                                                        options={val.options}
                                                        required={val.required || true}
                                                        customPatternName={val.custompattern}
                                                        placeholder={val.placeholder}
                                                        setValue={setValue}
                                                        valid={val.valid}
                                                        {...val}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        );
                                    })}
                                </Grid>
                                <Stack
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    gap="10px"
                                    marginTop="10px"
                                >
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="xs"
                                        disabled={isSubmitting}
                                        type="button"
                                        onClick={() => handleSubmit((data) => onSubmit(data, false))()}
                                    >
                                        Draft
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleSubmit((data) => onSubmit(data, true))()}
                                        color="info"
                                        disabled={isSubmitting}
                                        size="xs"
                                        type="button"
                                    >
                                        Publish
                                    </Button>
                                </Stack>
                            </form>
                        </CustomDialogTitle>
                    </Dialog>
                    {setting?.newsList?.length > 0 ? (
                        <Card>
                            <EnhancedTable enableSearch={false} columns={moduleColl} data={setting?.newsList || []} enableSort={true} />
                        </Card>
                    ) : (
                        <NoDataFound msg="Result will be shown after adding subscription plan." />
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default NewsFeedAdmin;
