import React from 'react';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

const fields = [
    {
        id: 'blog',
        name: 'B',
        title: 'Interesting Blogs',
        image: 'img/blog.jpeg',
        content:
            'A blog is a website or part of a website that regularly updates content, often in the form of personal commentary or business content. '
    },
    {
        id: 'new',
        name: 'N',
        title: 'Business News',
        image: 'img/news.webp',
        content:
            'News is a report or information about recent events, situations, or people and things that are considered interesting or important.'
    }
];

const KnowledgeReasearchView = ({ activeTab }) => {
    const navigate = useNavigate();

    return (
        <Box sx={{ padding: '15px', display: 'flex', flexDirection: 'row', gap: 13 }}>
            {fields.map((val) => (
                <Card
                    key={val.id}
                    onClick={() =>
                        val.id === 'blog' ? navigate('/knowledge-and-research/blog') : navigate('/knowledge-and-research/news')
                    }
                    sx={{ maxWidth: '45%', backgroundColor: 'rgb(231 248 255)', cursor: 'pointer' }}
                >
                    <CardHeader
                        avatar={
                            <Avatar sx={{ bgcolor: '#2196f3', color: 'black' }} aria-label="recipe">
                                {val.name}
                            </Avatar>
                        }
                        title={val.title}
                        // subheader="September 30, 2024"
                    />
                    <CardMedia component="img" height="194" image={val.image} alt={val.title} />
                    <CardContent>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {val.content}
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default KnowledgeReasearchView;
