import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { AppBar, Grid, Paper, Tab, Tabs, Typography, createStyles, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CommonCalculator from 'components/CommonComponent/CommonCalculator';

import { CustomListItemText } from 'assets/jss/HeaderStyle';
import { CustomListItem } from 'assets/jss/HeaderStyle';
import { MainBox } from 'assets/jss/GeneralStyle';
import { LargeContainer } from 'assets/jss/GeneralStyle';
import styles from 'assets/jss/material-kit-react/views/generalPage';

import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import { useDispatch, useSelector } from 'react-redux';
import CustomScroll from 'components/CustomScroll';
import Meta from 'components/meta';
import KnowledgeReasearchCategory from './KnowledgeReasearchCategory';

const mergedStyle = makeStyles((theme) =>
    createStyles({
        ...styles(theme)
    })
);

const fields = [{ name: 'All' }, { id: 'blogs', name: 'Blogs' }, { id: 'news', name: 'News' }];
const KnowledgeResearch = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = mergedStyle();
    const [value, setValue] = useState(0);
    const theme = useTheme();
    const [categorizeBlogs, setCategorizeBlogs] = useState({});
    const { dashboardBlogsList, blogDashboardCategoryList } = useSelector((state) => state.blogReducer);
    const [activeTab, setActiveTab] = useState(0);

    const typographyRef = useRef(null);
    useEffect(() => {
        // Set the ID after the component mounts
        if (typographyRef.current) {
            typographyRef.current.id = 'knowReasch';
        }
    }, []);

    useEffect(() => {
        if (activeTab === 1) {
            navigate('/knowledge-and-research/blog');
        } else if (activeTab === 2) {
            navigate('/knowledge-and-research/news');
        }
    }, [activeTab]);

    // const handleChangeIndex = (index) => {
    //     setActiveTab(index);
    //     setValue(index);
    // };
    // /**
    //  * handle the tab switch view
    //  * @param {*} event
    //  * @param {*} newValue
    //  */
    // const handleTabChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    // useEffect(() => {
    //     dispatch(fetchDashboardBlogsList());
    //     dispatch(getBlogList());
    //     dispatch(fetchBlogDashboardCategory());
    // }, []);

    // useEffect(() => {
    //     localStorage.setItem('myBlog', JSON.stringify(dashboardBlogsList));
    // }, [dashboardBlogsList?.length]);

    // useEffect(() => {
    //     const data = {};
    //     const arr = [];
    //     dashboardBlogsList.forEach((blog) => {
    //         const categoryName = blog.category_name;
    //         if (!data[categoryName]) {
    //             data[categoryName] = [];
    //         }
    //         if (blog.is_published) {
    //             data[categoryName].push(blog);
    //             arr.push(blog);
    //         }
    //     });
    //     const ModifiedObj = { All: arr, ...data };
    //     setCategorizeBlogs(ModifiedObj);
    // }, [dashboardBlogsList]);

    const breadcrumbs = [
        <CustomListItem className="cal-banner-menu" key="1" sx={{ m: 0 }} color="text.light">
            <CustomListItemText style={{ cursor: 'pointer' }} onClick={() => navigate('/')} primary={'Home'} />
        </CustomListItem>,
        <Typography ref={typographyRef} key="3" color="text.light" className="current-menu">
            News & Research
        </Typography>
    ];

    const tabs = fields?.map((category) => {
        return { label: category?.name };
    });

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    return (
        <main>
            <Meta
                title="In-Depth Knowledge Research | TPE Hub"
                description="Explore comprehensive research articles, studies, and insights on industry trends and innovations at TPE Hub. Stay informed and enhance your knowledge."
                page="/knowledge-and-research"
            />
            <CommonCalculator breadcrumbs={breadcrumbs} variantText="News & Research" />
            <MainBox>
                <LargeContainer maxWidth="xl">
                    <Paper elevation={0}>
                        <Grid container>
                            <Grid item xs={12} md={12}>
                                <AppBar position="static" color="default">
                                    <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
                                </AppBar>
                                {/* <SwipeableViews
                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                    index={value}
                                    onChangeIndex={handleChangeIndex}
                                > */}
                                {fields?.map((category, index) => {
                                    return (
                                        <PortfolioTabPanel value={value} index={index} dir={theme.direction}>
                                            <KnowledgeReasearchCategory
                                                researchCategory={category.name}
                                                blogsList={categorizeBlogs[category.name]}
                                                allCategories={category.name === 'All' ? true : false}
                                                activeTab={activeTab}
                                            />
                                        </PortfolioTabPanel>
                                    );
                                })}
                                {/* </SwipeableViews> */}
                            </Grid>
                        </Grid>
                    </Paper>
                </LargeContainer>
            </MainBox>
        </main>
    );
};

export default KnowledgeResearch;
