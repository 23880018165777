import React from 'react';
import { Box } from '@mui/material';
import NewsFeedTileView from './NewsFeedTileView';

function NewsFeedList({ newsList, newsCategory }) {
    // const [isListView, setIsListView] = useState(true);
    // const setView = () => {
    //     setIsListView(!isListView);
    // };
    return (
        <Box sx={{ padding: '15px' }}>
            <NewsFeedTileView newsCategory={newsCategory} newsList={newsList} />
        </Box>
    );
}

export default NewsFeedList;
