import { Paper, Typography } from '@mui/material';
import { DescriptionBox } from 'assets/jss/KnowledgeResearchStyle';
import React from 'react';

const NewsCategoryDescription = ({ newsCategory }) => {
    return (
        <Paper elevation={3}>
            <DescriptionBox>
                <Typography variant="h2" style={{ textTransform: 'capitalize' }}>
                    {newsCategory}
                </Typography>
                <Typography variant="body1">In this section, you can find all the interesting news.</Typography>
            </DescriptionBox>
        </Paper>
    );
};

export default NewsCategoryDescription;
