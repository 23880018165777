import {
    Grid,
    Tab,
    Tabs,
    Typography,
    useTheme,
    Button,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box
} from '@mui/material';

import { ChartCardParent, SummaryTabPaper, TopBlockBox } from 'assets/jss/PortfolioStyle';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { a11yProps, numberWithCommas } from 'utils/Utils';
import PortfolioTabPanel from 'views/PortfolioValueUpload/PortfolioTabPanel';
import * as tooltip from 'Tooltip.json';
import CustomTooltip from 'components/CommonComponent/CustomTooltip';
import BarDebtChart from './BarDebtChart';
import CustomScroll from 'components/CustomScroll';

const tabs = [{ label: 'Summary' }, { label: 'Lifetime Balance' }, { label: 'Cumulative Interest' }];

export default function DebtCalTopBlock() {
    const theme = useTheme();
    const [value, setValue] = useState(0);
    const debt = useSelector((state) => state.debt);
    const [activeTab, setActiveTab] = useState(0);
    const [expanded, setExpanded] = useState([
        { bool: false, key: '' },
        { bool: false, key: 'Debt by Type' },
        { bool: false, key: '' },
        { bool: false, key: '' },
        { bool: false, key: '' },
        { bool: false, key: 'Annual by Type' },
        { bool: false, key: 'Lifetime Interest by Type' }
    ]);

    const handleExpandClick = (index) => {
        setExpanded((prevExpanded) => {
            const newExpanded = [...prevExpanded];
            newExpanded[index] = { ...newExpanded[index], bool: !newExpanded[index]?.bool };
            return newExpanded;
        });
    };
    /**
     * handle the tab switch view
     * @param {*} event
     * @param {*} newValue
     */

    const handleTabChange = (event, newValue) => typeof newValue !== 'undefined' && setValue(newValue);

    const handleTabClick = (index) => {
        setActiveTab(index);
        setValue(index);
    };

    return (
        <TopBlockBox>
            <CustomScroll tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
            {/* <Tabs
                className="topblock-tabs"
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                allowScrollButtonsMobile={true}
                aria-label="debt calculator summary tabs"
            >
                <Tab label="Summary " {...a11yProps(0)} />
                <Tab label="Lifetime Balance" {...a11yProps(1)} />
                <Tab label="Cumulative Interest" {...a11yProps(2)} />
            </Tabs> */}
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={(index) => {
                    setActiveTab(index);
                    setValue(index);
                }}
            >
                <PortfolioTabPanel value={value} index={0} dir={theme.direction}>
                    <Grid container spacing={2}>
                        {Object.keys(debt.debtDetail).map((val, i) => {
                            return typeof debt.debtDetail[val] !== 'object' ? (
                                <>
                                    <Grid item xs={12} sm={3} key={i + 'budgetsummarytext'} className="widget-grid-item">
                                        <SummaryTabPaper elevation={3}>
                                            <Typography variant="subtitle1" className="summary-text">
                                                {val}
                                                {tooltip.default?.[val] && (
                                                    <CustomTooltip
                                                        tooltipText={tooltip.default?.[val]}
                                                        customTooltipStyle={{ maxWidth: 450 }}
                                                        label={val}
                                                    />
                                                )}
                                            </Typography>
                                            <Typography variant="subtitle1" className="summary-text">
                                                {numberWithCommas(debt.debtDetail[val])}
                                                <br />
                                                { debt.debtDetail['Total Annual Debt Payments'] == debt.debtDetail[val] ? (
                                                    <Typography component="span" style={{ color: 'black', fontSize: '12px' }}>
                                                        *{numberWithCommas((debt.debtDetail[val] / 12).toFixed(2).replace(/\.00$/, ''))} monthly
                                                    </Typography>
                                                ): "" }
                                            </Typography>
                                            {val.toLowerCase() !== 'debt ratio' && (
                                                <>
                                                    <Button onClick={() => handleExpandClick(i)} size="small" color="primary">
                                                        {expanded[i]?.bool ? 'Less Info' : 'More Info'}
                                                    </Button>
                                                    {expanded[i]?.bool && (
                                                        <Collapse in={expanded[i]?.bool} timeout="auto" unmountOnExit>
                                                            {Object.keys(debt.debtDetail[expanded[i]?.key]).map((k) => (
                                                                <TableContainer component={Paper}>
                                                                    <Table>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell align="left">{k}</TableCell>
                                                                                <TableCell align="right">
                                                                                    {numberWithCommas(
                                                                                        Math.floor(debt.debtDetail[expanded[i]?.key][k])
                                                                                    )}
                                                                                    <br />
                                                                                    { debt.debtDetail['Annual by Type'][k] == debt.debtDetail[expanded[i]?.key][k] ? (
                                                                                        <Box
                                                                                            sx={{
                                                                                                backgroundColor: '#3d3d3d',
                                                                                                color: 'white',
                                                                                                borderRadius: '6px',
                                                                                                width: 'max-content',
                                                                                                padding: '2px',
                                                                                                display: 'inline-block'
                                                                                            }}
                                                                                        >
                                                                                            <Typography
                                                                                                component="span"
                                                                                                style={{ color: 'white', fontSize: '12px' }}
                                                                                            >
                                                                                                *
                                                                                                {numberWithCommas(
                                                                                                    (
                                                                                                        Math.floor(
                                                                                                            debt.debtDetail[expanded[i]?.key][k]
                                                                                                        ) / 12
                                                                                                    )
                                                                                                        .toFixed(2)
                                                                                                        .replace(/\.00$/, '')
                                                                                                )}{' '}
                                                                                                monthly
                                                                                            </Typography>
                                                                                            
                                                                                        </Box>
                                                                                    ) : ""}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            ))}
                                                        </Collapse>
                                                    )}
                                                </>
                                            )}
                                        </SummaryTabPaper>
                                    </Grid>
                                </>
                            ) : (
                                <></>
                            );
                        })}
                    </Grid>
                </PortfolioTabPanel>
                <PortfolioTabPanel value={value} index={1} dir={theme.direction}>
                    {Object.keys(debt.debtDetail).length ? (
                        <ChartCardParent elevation={2}>
                            {/* <BarChart
                                rotateLabel={true}
                                divideLegend={true}
                                marginProp={{ top: 20, right: 20, bottom: 70, left: 50 }}
                                formatXAxis={true}
                                groupMode="stacked"
                                chartData={{
                                    data: debt.debtDetail['Lifetime Balance']
                                }}
                            /> */}
                            <BarDebtChart debt={debt.debtDetail['Lifetime Balance']} />
                        </ChartCardParent>
                    ) : (
                        ''
                    )}
                </PortfolioTabPanel>
                <PortfolioTabPanel value={value} index={2} dir={theme.direction}>
                    {Object.keys(debt.debtDetail).length ? (
                        <ChartCardParent elevation={2}>
                            <BarDebtChart debt={debt.debtDetail['Cumulative Interest Array']} />
                        </ChartCardParent>
                    ) : (
                        ''
                    )}
                </PortfolioTabPanel>
            </SwipeableViews>
        </TopBlockBox>
    );
}
